
// @ts-nocheck
import { Component, Vue, Watch } from 'vue-property-decorator'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import {
  getAssistantsOfEvent,
  getAllAssistantsOfEvent, 
  getAssistantsExcel,
  getPreregisteredAssistants,
  approveAssistant,
  rejectAssistant,
  hasPreRegisterProduct
} from '@/services/seller.service'
import { ErrorMessages } from '@/helpers/constants'
import { userYear } from '@/helpers/date'
import { idoDateToDateAndHours } from '@/helpers/date'
import _ from 'lodash'
import axios from 'axios'




@Component({
  components: {
    SellerHeader,
  },
})
export default class Assistants extends Vue {
  eventId!: number = localStorage.getItem('selectedEvent')
  public filter = ''
  public totalPages = 1
  public page = 1

  public assistants = []
  public assistantsFiltered = []

  public preregisteredAssistants = []
  public preregisteredAssistantsFiltered = []

  public skeletonCount = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  public loading = false
  public loadingPre = false

  public custom_field_1: string = localStorage.getItem('custom_field_1')
  public custom_field_2: string = localStorage.getItem('custom_field_2')
  public custom_field_3: string = localStorage.getItem('custom_field_3')

  public verified = 'all'
  public activeTab: number = 0

  public selectedAssistant: any = null;
  public selectedAction: 'approve' | 'reject' | null = null;


  public eventHasPreRegister: boolean | null = null;

  //Filtros
  public verifiedFilter: any = 'all'; // Valor por defecto
  public verifiedOptions: any[] = [
    { text: 'Todos', value: 'all' },
    { text: 'Verificados', value: true },
    { text: 'No verificados', value: false }
  ];

  public preRegisterFilter: any = 'all';
  public preRegisterOptions: any[] = [
    { text: 'Todos', value: 'all' },
    { text: 'Con pre-registro', value: 1 },
    { text: 'Sin pre-registro', value: 0 }
  ];

  public preRegisterStatusFilter: any = 'all'; 
  public preRegisterStatusOptions: any[] = [
    { text: 'Todos', value: 'all' },
    { text: 'Aprobados', value: 'approved' },
    { text: 'Desaprobados', value: 'rejected' }
  ];

  async getAssistantsOfEvent(page?, query?, verified?) {
    try {
      if (!query) this.loading = true
      const { data, meta } = await getAssistantsOfEvent(
        this.eventId,
        page,
        100,
        query,
        verified
      )
      this.totalPages = Math.ceil(meta.total / 100)
      this.assistants = data;
      this.applyFilters();
      this.loading = false
      if (verified) this.verified = verified
    } catch (e) {
      Vue.$toast.error(ErrorMessages.COMMON)
      this.loading = false
    }
  }

  applyFilters() {
  let filtered = [...this.assistants];

  if (this.verifiedFilter !== 'all') {
    const verifiedValue = Number(this.verifiedFilter);
    filtered = filtered.filter(a => Number(a.verified) === verifiedValue);
  }

  if (this.eventHasPreRegister && this.preRegisterStatusFilter !== 'all') {
    filtered = filtered.filter(a => a.preregistration_status === this.preRegisterStatusFilter);
  }

  this.assistantsFiltered = _.orderBy(filtered, 'created_at', 'desc');
}


  onVerifiedFilterChange(value: any) {
    this.verifiedFilter = value;
    this.applyFilters();
  }

  onPreRegisterStatusFilterChange(value: any) {
  this.preRegisterStatusFilter = value;
  this.loading = true; 

  this.assistants = [];
  this.assistantsFiltered = [];


  if (this.preRegisterStatusFilter !== 'all') {
    getAllAssistantsOfEvent(this.eventId, this.filter, this.verified)
      .then(response => {
        this.assistants = response.data;
        this.applyFilters();
        this.loading = false;
      })
      .catch(e => {
        Vue.$toast.error(ErrorMessages.COMMON);
        this.loading = false;
      });
  } else {
    this.getAssistantsOfEvent(1, this.filter, this.verified)
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }
}





  formatBirthday(birthday: string): string {
    const date = new Date(birthday);

    const formattedDate = new Intl.DateTimeFormat("es-ES", {
      day: "2-digit",
      month: "long",
      year: "numeric"
    }).format(date);

    const age = this.getUserAge(date);

    return `${formattedDate} (${age} años)`;
  }


  // Métodos de cambio de filtros
  onVerifiedFilterChange(value: any) {
    this.verifiedFilter = value;
    this.applyFilters();
  }

  onPreRegisterFilterChange(value: any) {
    this.preRegisterFilter = value;
    this.applyFilters();
  }

  async fetchEventPreRegisterStatus() {
    try {
      const res = await hasPreRegisterProduct(this.eventId);
      this.eventHasPreRegister = res.hasPreRegister;
    } catch (error) {
      this.eventHasPreRegister = false;
      Vue.$toast.error('Error al obtener la información de pre-registro del evento.');
    }
  }

  async fetchPreregisteredAssistants() {
    try {
      this.loadingPre = true
      const response = await getPreregisteredAssistants(this.eventId)

      this.preregisteredAssistants = response.data || []
      this.preregisteredAssistantsFiltered = _.orderBy(this.preregisteredAssistants, 'created_at', 'desc')
      this.loadingPre = false
    } catch (e) {
      Vue.$toast.error('Error al obtener asistentes pre-registrados')
      this.loadingPre = false
    }
  }

  public async rejectAssistant(ticketUuid: string, assistantUuid: string): Promise<void> {
    try {
      await rejectAssistant(ticketUuid, assistantUuid);
      Vue.$toast.success('Asistente Rechazado con Exito');
      this.fetchPreregisteredAssistants();
    } catch (e) {
      Vue.$toast.error('Error al rechazar el asistente');
    }
  }

  public async approveAssistant(ticketUuid: string, assistantUuid: string): Promise<void> {
    try {
      await approveAssistant(ticketUuid, assistantUuid);
      Vue.$toast.success('Asistente aprobado con Exito');
      this.fetchPreregisteredAssistants();
    } catch (e) {
      Vue.$toast.error('Error al aprobar el asistente');
    }
  }

  openActionModal(assistant: any, action: 'approve' | 'reject') {
    this.selectedAssistant = assistant;
    this.selectedAction = action;
    this.$bvModal.show('approval-modal');
  }

  async confirmAction() {
    if (!this.selectedAssistant || !this.selectedAction) return;
    try {
      if (this.selectedAction === 'approve') {
        await approveAssistant(
          this.selectedAssistant.ticket_uuid || 'ticket-uuid-por-defecto',
          this.selectedAssistant.uuid
        );
        this.$toast.success('Asistente aprobado');
      } else {
        await rejectAssistant(
          this.selectedAssistant.ticket_uuid || 'ticket-uuid-por-defecto',
          this.selectedAssistant.uuid
        );
        this.$toast.success('Asistente rechazado');
      }
      this.fetchPreregisteredAssistants();
    } catch (error) {
      this.$toast.error(
        this.selectedAction === 'approve'
          ? 'Error al aprobar el asistente'
          : 'Error al rechazar el asistente'
      );
    } finally {
      this.$bvModal.hide('approval-modal');
    }
  }

  cancelAction() {
    this.$bvModal.hide('approval-modal');
  }


  resetModal() {
    this.selectedAssistant = null;
    this.selectedAction = null;
  }





  onFilterChange() {
    if (this.activeTab === 0) {
      this.getAssistantsOfEvent(1, this.filter, this.verified)
    } else {
      this.fetchPreregisteredAssistants()
    }
  }

  setVerified(value: any) {
    this.verified = value
    this.getAssistantsOfEvent(1, this.filter, value)
  }

  formatDate(isoDate: string) {
    return idoDateToDateAndHours(isoDate)
  }

  getUserAge(date: Date) {
    return userYear(date)
  }

  getUserGender(gender: string) {
    if (gender === 'male') return 'Masculino'
    else if (gender === 'female') return 'Femenino'
    else return 'Otro'
  }

  searchAssistant() {
    if (this.activeTab === 0) {
      const filtered = this.assistants.filter(assistant =>
        assistant.id.toString().includes(this.filter) &&
        assistant.fullname.toLowerCase().includes(this.filter.toLowerCase())
      )
      this.assistantsFiltered = _.orderBy(filtered, 'created_at', 'desc')
    } else {
      const filtered = this.preregisteredAssistants.filter(assistant =>
        assistant.id.toString().includes(this.filter) &&
        assistant.fullname.toLowerCase().includes(this.filter.toLowerCase())
      )
      this.preregisteredAssistantsFiltered = _.orderBy(filtered, 'created_at', 'desc')
    }
  }

  @Watch('page', { immediate: true, deep: true })
  onPageChange(nextPage: number, _currentPage: unknown) {
    if (this.activeTab === 0) {
      this.getAssistantsOfEvent(nextPage, this.filter, this.verified)
    }
  }

  async getAssistantsExcel() {
    const loader = Vue.$loading.show()
    const response = await getAssistantsExcel(this.eventId)
    loader.hide()
    return response
  }

  @Watch('activeTab')
  onTabChange(newTab: number) {
    if (newTab === 0) {
      this.getAssistantsOfEvent(1, this.filter, this.verified)
    } else if (newTab === 1) {
      this.fetchPreregisteredAssistants()
    }
  }

  async mounted() {
    await this.fetchEventPreRegisterStatus();
    this.getAssistantsOfEvent(1, this.filter, this.verified)
  }
}
